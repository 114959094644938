var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-card-title',[_vm._v(" Registros "),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"primary"},on:{"click":_vm.newRegister}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Nuevo ")],1)]}}])},[_c('span',[_vm._v("Nuevo registro")])])],1),_c('v-card-subtitle',[_vm._v(" Disponibles: XX ")]),_c('v-breadcrumbs',{attrs:{"items":_vm.routes},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-breadcrumbs-item',{attrs:{"to":item.href,"disabled":item.disabled}},[_vm._v(" "+_vm._s(item.text)+" ")])]}}])})],1),_c('v-text-field',{attrs:{"label":"Buscar","append-icon":"mdi-magnify","clearable":""},model:{value:(_vm.registers_search),callback:function ($$v) {_vm.registers_search=$$v},expression:"registers_search"}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.registers_headers,"items":_vm.registers_data,"search":_vm.registers_search,"loading":_vm.registers_loading,"loading-text":_vm.registers_loading_text,"show-expand":"","no-data-text":"Sin datos"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.status[0] == 0)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","small":"","color":"warning","rounded":""},on:{"click":function($event){return _vm.viewRegister(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver")])]),_vm._v(" | "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","small":"","color":"error","rounded":""},on:{"click":function($event){return _vm.deleteRegister(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])],1):_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","small":"","color":"warning","rounded":""},on:{"click":function($event){return _vm.viewRegister(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver")])])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.signers_headers,"items":item.signers,"hide-default-footer":""}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }