<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        Registros
        <v-spacer></v-spacer>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              text
              color="primary"
              @click="newRegister"
            >
              <v-icon>mdi-plus</v-icon>
              Nuevo
            </v-btn>
          </template>
          <span>Nuevo registro</span>
        </v-tooltip>
      </v-card-title>

      <v-card-subtitle>
        Disponibles: XX
      </v-card-subtitle>

      <v-breadcrumbs :items="routes">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :to="item.href" :disabled="item.disabled">
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </v-card>

    <v-text-field
      v-model="registers_search"
      label="Buscar"
      append-icon="mdi-magnify"
      clearable
    ></v-text-field>

    <v-data-table
      :headers="registers_headers"
      :items="registers_data"
      :search="registers_search"
      :loading="registers_loading"
      :loading-text="registers_loading_text"
      show-expand
      no-data-text="Sin datos"
      class="elevation-1"
    >
      <template v-slot:item.actions="{ item }">
        <div v-if="item.status[0] == 0">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                dark
                small
                color="warning"
                rounded
                @click="viewRegister(item)"
              >
                <v-icon small>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>Ver</span>
          </v-tooltip>
          |
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" dark small color="error" rounded @click="deleteRegister(item)">
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </div>

        <div v-else>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                dark
                small
                color="warning"
                rounded
                @click="viewRegister(item)"
              >
                <v-icon small>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>Ver</span>
          </v-tooltip>
        </div>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-data-table
            :headers="signers_headers"
            :items="item.signers"
            hide-default-footer
            class="elevation-1"
          ></v-data-table>
        </td>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  name: "RegisterIndex",
  components: {},
  data: () => ({
    // Navigation
    routes: [
      {
        text: "Registros",
        disabled: true,
        href: "/register/index",
      },
    ],
    // Form Data
    registers_search: null,
    registers_loading: true,
    registers_loading_text: "Obteniendo datos",
    registers_headers: [
      {
        text: "Nombre del archivo",
        align: "start",
        sortable: false,
        value: "file_file_name",
      },
      { text: "Liga del archivo", value: "url_archivo" },
      { text: "Estatus", value: "state" },
      //{ text: "Estatus", value: "status[1]" },
      { text: "Acciones", value: "actions" },
    ],
    registers_data: [],
    signers_headers: [
      {
        text: "Nombre",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Correo", value: "email" },
      { text: "RFC", value: "tax_id" },
    ],
    registers_avaibles: 0 
  }),
  mounted() { 
    this.valityUser();
    this.getRegisters();
  },
  methods: {
    valityUser(){
      let role = this.$store.getters.getUser.role;

      if (role != 'admin') this.$router.push("/register/index/my");
    },
    getRegisters() {
      this.registers_loading = true;
      this.registers_loading_text = "Obteniendo datos";

      this.$axios
        .get("documents")
        .then((response) => {
          this.registers_data = response.data;

          
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.registers_loading = false));
    },
    viewRegister(item) {
      this.$router.push("/register/view/" + item.id);
    },
    newRegister() {
      this.$router.push("/register/new");
    },
    deleteRegister(item) {
      this.registers_loading = true;
      this.registers_loading_text = "Eliminando registro";
      this.registers_data = [];

      this.$axios
        .delete("documents/" + item.id)
        .then((response) => {
          this.deleteMyRegister(item.id);
        })
        .catch((error) => {
          console.log(error.response.data);
        })
        .finally(() => (this.registers_loading = false));
    },
    deleteMyRegister(id) {
      console.log(id);
      this.$axios
        .delete("https://fiel.compratec.com.mx/backend/public/api/file/" + id)
        .then((response) => {
          this.getRegisters();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
